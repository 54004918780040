import { ENVIRONMENT } from 'config';
import store from 'store/index';
import { SLICE } from 'modules/dashboard/constants';
import faroLog, { faro } from './faro';
import { updateUserActiveness } from './active-user-time';

let sessionId;
let user;

const writeLog = (filterName, filters) => {
  if (!sessionId) {
    store?.subscribe(() => {
      sessionId = store?.getState()[SLICE.BASE_AUTH]?.sessionId;
      user = store?.getState()[SLICE.BASE_AUTH]?.user;
    });
    sessionId = store?.getState()[SLICE.BASE_AUTH]?.sessionId;
    user = store?.getState()[SLICE.BASE_AUTH]?.user;
  }

  if (ENVIRONMENT.PBI_EMBED_DEBUG_MODE?.toLowerCase() === 'true') {
    let filterLog = `${filterName} : ${new Date().toISOString()} - `;

    filters.forEach((filter) => {
      if (filter?.$schema?.includes('basic')) {
        filterLog = filterLog?.concat(
          `{table: '${filter?.target?.table}', column : '${filter?.target?.column}'} '${
            filter?.operator
          }' [${filter?.values?.join(',')}] | `
        );
      } else {
        let advancedCondition = '';
        filter?.conditions?.forEach((con) => {
          advancedCondition = advancedCondition?.concat(
            `{operator: '${con.operator}', value: '${con.value}'} `
          );
        });
        filterLog = filterLog?.concat(
          `{table: '${filter?.target?.table}', column : '${filter?.target?.column}'} '${filter?.logicalOperator}' [${advancedCondition}] | `
        );
      }
    });

    // eslint-disable-next-line no-console
    console.log(filterLog);

    if (!faro.api.getSession()?.id) {
      faro?.api.setUser({ username: user?.username });
      faro?.api.setSession({ id: sessionId });
    }

    faroLog(filterLog, 'INFO', { sessionId, visualtype: 'filter' });

    // this will send a signal to update the current chunk as active
    updateUserActiveness();
  }
};

export default writeLog;
