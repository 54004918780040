import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectHotelName,
  selectTargetDate,
  selectVisualFilters,
  selectPmsSync,
  selectPaceHorizon,
  selectEnableHotelGroups,
  selectReportType,
} from 'modules/dashboard/selectors';
import { PACE_HORIZON_SELECTION, REPORT_TYPE, TAGS } from 'modules/dashboard/constants';
import {
  getBookingDateSchema,
  getBreakdownAndFocusOnSchema,
  getDashboardPmsSyncSchema,
  getHotelIdSchema,
  getPaceGranularitySchema,
  getPaceWidgetPaceHorizonSchema,
  getPmsSyncSchema,
  getTargetDateSchema,
} from 'modules/dashboard/components/tab-container/inner-filter/functions';
import { paceActions } from 'modules/dashboard/components/tab-container/pace-tabs/slice';
import {
  selectAdjustedEndDate,
  selectAdjustedStartDate,
  selectGranularity,
  selectIsFocusOnSet,
  selectIsPaceDetailed,
  selectIsPrimary,
  selectPaceBookingDate,
  selectPaceBookingPaceWindow,
  selectPaceChartBreakdown,
  selectPaceFocusOn,
  selectPaceSegmentTabularFilterList,
  selectPaceWidgetSegmentFilterList,
  selectTabularView,
} from 'modules/dashboard/components/tab-container/pace-tabs/selectors';
import writeLog from 'modules/common/utils/filter-log';
import { storeVisualMapper } from 'modules/common/utils/filter-visual-mapper';
import VISUAL_MAPPER from 'modules/common/utils/visual-mapper';
import { GRANULARITY } from '../constants';

/**
 * Custom hook to generate summary widget filter schemas
 */
const usePaceSegmentWidget = () => {
  const dispatch = useDispatch();
  //
  const targetDate = useSelector(selectTargetDate);
  const hotelId = useSelector(selectHotelName);
  const visualFilters = useSelector(selectVisualFilters);
  const pmsSyncToggle = useSelector(selectPmsSync);
  const paceHorizon = useSelector(selectPaceHorizon);
  const focusOn = useSelector(selectPaceFocusOn);
  const chartBreakdown = useSelector(selectPaceChartBreakdown);
  const isDetailed = useSelector(selectIsPaceDetailed);
  const enableHotelGroups = useSelector(selectEnableHotelGroups);
  const paceSegmentVisualFilterList = useSelector(selectPaceWidgetSegmentFilterList);
  const isSegment = useSelector(selectIsPrimary);
  const reportType = useSelector(selectReportType);
  const bookingDate = useSelector(selectPaceBookingDate);
  const bookingPaceWindow = useSelector(selectPaceBookingPaceWindow);
  const isTabular = useSelector(selectTabularView);
  const adjustedStartDate = useSelector(selectAdjustedStartDate);
  const adjustedEndDate = useSelector(selectAdjustedEndDate);
  const granularity = useSelector(selectGranularity);
  const tabularFilterList = useSelector(selectPaceSegmentTabularFilterList);
  const IsFocusOnSet = useSelector(selectIsFocusOnSet);

  // Generate filter list for pace segment visuals
  useEffect(() => {
    if (
      !IsFocusOnSet ||
      reportType !== REPORT_TYPE.PACE ||
      !chartBreakdown ||
      !paceHorizon ||
      !hotelId ||
      !visualFilters
    ) {
      return;
    }
    //
    const targetDateFilter = getTargetDateSchema(
      visualFilters,
      targetDate?.startDate,
      targetDate?.endDate
    );
    //
    let startDate;
    let endDate;
    if (isTabular) {
      if (granularity === GRANULARITY.DAILY) {
        startDate = bookingDate?.startDate;
        endDate = bookingDate?.endDate;
      } else {
        startDate = adjustedStartDate;
        endDate = adjustedEndDate;
      }
    } else {
      startDate = bookingDate?.startDate;
      endDate = bookingDate?.endDate;
    }
    const bookingDateFilter = getBookingDateSchema(visualFilters, startDate, endDate);
    //
    let hotelIdFilter = [];
    if (enableHotelGroups) {
      const hotelListIds = hotelId?.hotels?.map((hotel) => hotel?.databricksId);
      hotelIdFilter = getHotelIdSchema(hotelListIds, visualFilters);
    } else {
      hotelIdFilter = getHotelIdSchema(hotelId?.databricksId, visualFilters);
    }
    const paceHorizonFilter = getPaceWidgetPaceHorizonSchema(
      visualFilters,
      paceHorizon,
      bookingPaceWindow === PACE_HORIZON_SELECTION.BOOKING_PACE_HORIZON
    );
    const segmentAndFocusOnFilter = getBreakdownAndFocusOnSchema(
      visualFilters,
      chartBreakdown,
      isDetailed,
      focusOn
    );
    const pmsSyncFilter = getPmsSyncSchema(visualFilters, pmsSyncToggle, TAGS.PACE_WIDGET);
    const granularityFilter = getPaceGranularitySchema(visualFilters, granularity);
    const pmsSyncSchema = getDashboardPmsSyncSchema(pmsSyncToggle);

    if (
      targetDateFilter &&
      hotelIdFilter &&
      paceHorizonFilter &&
      pmsSyncFilter &&
      segmentAndFocusOnFilter &&
      bookingDateFilter
    ) {
      const filterList = [
        targetDateFilter,
        hotelIdFilter,
        paceHorizonFilter,
        ...segmentAndFocusOnFilter,
        ...pmsSyncFilter,
        bookingDateFilter,
        ...pmsSyncSchema,
      ];
      if (JSON.stringify(paceSegmentVisualFilterList) !== JSON.stringify(filterList)) {
        storeVisualMapper(VISUAL_MAPPER.paceSegmentVisualFilter);
        //
        writeLog('Pace Segment Filters', filterList);
        dispatch(paceActions.setPaceWidgetSegmentFilterList(filterList));
      }
      // tabular filter list
      const tabularFilters = [
        targetDateFilter,
        hotelIdFilter,
        paceHorizonFilter,
        ...segmentAndFocusOnFilter,
        ...pmsSyncFilter,
        bookingDateFilter,
        granularityFilter,
        ...pmsSyncSchema,
      ];
      if (JSON.stringify(tabularFilterList) !== JSON.stringify(tabularFilters)) {
        storeVisualMapper(VISUAL_MAPPER.paceSegmentTabularFilterList);
        //
        writeLog('Pace Segment Tabular Filters', tabularFilters);
      }
      dispatch(paceActions.setPaceSegmentTabularFilterList(tabularFilters));
    }
  }, [
    pmsSyncToggle,
    visualFilters,
    targetDate,
    hotelId,
    paceHorizon,
    focusOn,
    chartBreakdown,
    isDetailed,
    enableHotelGroups,
    reportType,
    isSegment,
    bookingDate,
    bookingPaceWindow,
    isTabular,
    adjustedStartDate,
    adjustedEndDate,
    granularity,
    IsFocusOnSet,
  ]);
};
//
export default usePaceSegmentWidget;
