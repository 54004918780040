import { FormControl, Grid, Alert, MenuItem, Button, Autocomplete } from '@mui/material';
import ERROR_TYPES from 'modules/common/constants/error-types';
import TextField from '@mui/material/TextField';
import { notificationActions } from 'modules/common/notifications/slice';
import { selectNotification } from 'modules/common/notifications/selectors';
import { hotelMetadataValidation } from 'modules/users/validation/add-user-form-validation';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useRef, useState } from 'react';
import { isEmpty } from 'modules/common/helpers/object';
import { selectOrganizationId } from 'modules/common/auth/selectors';
import { Formik } from 'formik';
import { selectHotelDropdownList } from 'modules/dashboard/selectors';
import { usersActions } from 'modules/users/slice';
import { selectCurrencyData } from 'modules/users/selectors';
import OutlinedDiv from './outer-card';

const FormStyles = { borderRadius: 10 };
//
const HotelMetaDataView = ({ userId, onClose }) => {
  //
  const dispatch = useDispatch();
  const notification = useSelector(selectNotification);
  const hotelList = useSelector(selectHotelDropdownList);
  const organizationId = useSelector(selectOrganizationId);
  const currencyData = useSelector(selectCurrencyData);
  //
  const [hotel, setHotel] = useState(null);
  const [initialValues, setInitialValues] = useState({
    id: '',
    name: '',
    roomCapacity: '',
    timezone: '',
    chainName: '',
    city: '',
    state: '',
    country: '',
    hotelType: '',
    chainId: '',
    hotelCode: '',
    ibe: '',
    interface: '',
    phram: '',
    phrdnName: '',
    pms: '',
    postalCode: '',
    propertyLatitude: '',
    propertyLongitude: '',
    streetAddress: '',
    localCurrency: '',
  });
  const formElement = useRef(null);
  // reset notifications on first render
  useEffect(() => {
    dispatch(notificationActions.resetNotification());
  }, []);
  // get only changed values
  const getChangedValues = (values, initials) =>
    Object.entries(values).reduce((acc, [key, value]) => {
      const hasChanged = initials[key] !== value;
      if (hasChanged) {
        acc[key] = value;
      }
      return acc;
    }, {});
  //
  const onSubmitUpdateHotel = async (values, { setSubmitting }) => {
    const onlyUpdatedValues = getChangedValues(values, initialValues);
    if (!isEmpty(onlyUpdatedValues)) {
      const valueObj = {
        organizationId,
        userId,
        values: {
          hotelName: values.name,
          hotelId: values.id,
          ...onlyUpdatedValues,
        },
      };
      //
      dispatch(usersActions.updateHotelMetadata(valueObj));
    }
    setSubmitting(false);
    onClose();
  };
  //
  useEffect(() => {
    if (hotel !== null) {
      setInitialValues({
        id: hotel?.id,
        name: hotel?.label,
        roomCapacity: hotel?.roomCapacity,
        databricksHotelId: hotel?.databricksHotelId,
        displayName: hotel?.displayName,
        timezone: hotel?.timezone,
        chainName: hotel?.chainName,
        city: hotel?.city,
        state: hotel?.state,
        country: hotel?.country,
        hotelType: hotel?.hotelType,
        chainId: hotel?.chainId,
        hotelCode: hotel?.hotelCode,
        ibe: hotel?.ibe,
        interface: hotel?.interface,
        phram: hotel?.phram,
        phrdnName: hotel?.phrdnName,
        pms: hotel?.pms,
        postalCode: hotel?.postalCode,
        propertyLatitude: hotel?.propertyLatitude,
        propertyLongitude: hotel?.propertyLongitude,
        streetAddress: hotel?.propertyLocation,
        localCurrency: hotel?.localCurrency,
      });
    }
  }, [hotel]);
  //
  const removeDuplicates = (array) => {
    const uniqueArray = array.filter((item, index) => {
      // Find the index of the first occurrence of the item in the array
      const firstIndex = array.findIndex((obj) => obj.currencyCode === item.currencyCode);
      // Return true only if the current index matches the first index
      return index === firstIndex;
    });
    return uniqueArray;
  };
  //
  return (
    <Grid
      xs={12}
      md={8}
      mt={2}
      component={Grid}
      container
      item
      display="flex"
      justifyContent="space-between"
      direction="column"
      sx={{
        backgroundColor: 'white',
        flexShrink: 0,
      }}
    >
      <Formik
        initialValues={initialValues}
        enableReinitialize
        validationSchema={hotelMetadataValidation}
        onSubmit={onSubmitUpdateHotel}
      >
        {({
          handleBlur,
          handleChange,
          handleSubmit,
          values,
          isSubmitting,
          dirty,
          isValid,
          touched,
          errors,
          setFieldValue,
        }) => (
          <form noValidate onSubmit={handleSubmit}>
            <Grid container spacing={2} maxHeight={400} sx={{ overflow: 'auto' }}>
              {notification?.isEnabled && notification?.type === ERROR_TYPES.ERROR && (
                <Alert mt={2} mb={3} severity={notification?.type}>
                  {notification?.message}
                </Alert>
              )}
              <Grid container justifyContent="space-around" alignItems="center" my={2}>
                <Autocomplete
                  fullWidth
                  sx={{
                    mb: 2,
                    mt: 2,
                  }}
                  options={hotelList ?? []}
                  getOptionLabel={(option) => (option.label ? option.label : '')}
                  isOptionEqualToValue={(option, value) => value !== null && option.id === value.id}
                  filterSelectedOptions
                  value={hotel}
                  onChange={(event, value) => {
                    setHotel(value);
                  }}
                  disableClearable
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      InputLabelProps={{ shrink: true }}
                      label="Select Hotel"
                      size="small"
                      color="primary"
                    />
                  )}
                />
              </Grid>
              <OutlinedDiv label="Hotel Details">
                <Grid
                  container
                  justifyContent="space-around"
                  alignItems="center"
                  px={4}
                  mb={2}
                  mt={6}
                >
                  <FormControl sx={FormStyles} fullWidth>
                    <TextField
                      name="name"
                      onChange={handleChange}
                      onBlur={(e) => {
                        const trimmedValue = e.target.value.trim();
                        setFieldValue('name', trimmedValue);
                        handleBlur(e);
                      }}
                      value={values.name}
                      label="Name"
                      variant="outlined"
                      error={Boolean(touched.name && errors.name)}
                      helperText={touched.name && errors.name}
                      size="large"
                      disabled={hotel === null}
                    />
                  </FormControl>
                </Grid>
                <Grid container justifyContent="space-around" alignItems="center" px={4} my={2}>
                  <FormControl sx={FormStyles} fullWidth>
                    <TextField
                      name="chainId"
                      onChange={handleChange}
                      onBlur={(e) => {
                        const trimmedValue = e.target.value.trim();
                        setFieldValue('chainId', trimmedValue);
                        handleBlur(e);
                      }}
                      value={values.chainId}
                      label="Chain Id"
                      variant="outlined"
                      size="large"
                      disabled={hotel === null}
                    />
                  </FormControl>
                </Grid>
                <Grid container justifyContent="space-around" alignItems="center" px={4} my={2}>
                  <FormControl sx={FormStyles} fullWidth>
                    <TextField
                      name="chainName"
                      onChange={handleChange}
                      onBlur={(e) => {
                        const trimmedValue = e.target.value.trim();
                        setFieldValue('chainName', trimmedValue);
                        handleBlur(e);
                      }}
                      value={values.chainName}
                      label="Chain Name"
                      variant="outlined"
                      size="large"
                      disabled={hotel === null}
                    />
                  </FormControl>
                </Grid>
                <Grid container justifyContent="space-around" alignItems="center" px={4} my={2}>
                  <FormControl sx={FormStyles} fullWidth>
                    <TextField
                      type="number"
                      name="roomCapacity"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.roomCapacity ? values.roomCapacity : 0}
                      label="Room Capacity"
                      variant="outlined"
                      size="large"
                      disabled={hotel === null}
                    />
                  </FormControl>
                </Grid>
                <Grid container justifyContent="space-around" alignItems="center" px={4} my={2}>
                  <FormControl sx={FormStyles} fullWidth>
                    <TextField
                      size="large"
                      select
                      name="localCurrency"
                      value={values.localCurrency}
                      label="Local Currency"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      fullWidth
                      disabled={hotel === null}
                    >
                      {removeDuplicates(currencyData)?.map((type) => (
                        <MenuItem key={`${type.currencyCode}`} value={type.currencyCode}>
                          {type.currencyCode}
                        </MenuItem>
                      ))}
                    </TextField>
                  </FormControl>
                </Grid>
                <Grid container justifyContent="space-around" alignItems="center" px={4} my={2}>
                  <FormControl sx={FormStyles} fullWidth>
                    <TextField
                      name="timezone"
                      onChange={handleChange}
                      onBlur={(e) => {
                        const trimmedValue = e.target.value.trim();
                        setFieldValue('timezone', trimmedValue);
                        handleBlur(e);
                      }}
                      value={values.timezone}
                      label="Timezone"
                      variant="outlined"
                      size="large"
                      disabled={hotel === null}
                    />
                  </FormControl>
                </Grid>
                <Grid container justifyContent="space-around" alignItems="center" px={4} my={2}>
                  <FormControl sx={FormStyles} fullWidth>
                    <TextField
                      name="city"
                      onChange={handleChange}
                      onBlur={(e) => {
                        const trimmedValue = e.target.value.trim();
                        setFieldValue('city', trimmedValue);
                        handleBlur(e);
                      }}
                      value={values.city}
                      label="City"
                      variant="outlined"
                      size="large"
                      disabled={hotel === null}
                    />
                  </FormControl>
                </Grid>
                <Grid container justifyContent="space-around" alignItems="center" px={4} my={2}>
                  <FormControl sx={FormStyles} fullWidth>
                    <TextField
                      name="state"
                      onChange={handleChange}
                      onBlur={(e) => {
                        const trimmedValue = e.target.value.trim();
                        setFieldValue('state', trimmedValue);
                        handleBlur(e);
                      }}
                      value={values.state}
                      label="State"
                      variant="outlined"
                      size="large"
                      disabled={hotel === null}
                    />
                  </FormControl>
                </Grid>
                <Grid container justifyContent="space-around" alignItems="center" px={4} my={2}>
                  <FormControl sx={FormStyles} fullWidth>
                    <TextField
                      name="country"
                      onChange={handleChange}
                      onBlur={(e) => {
                        const trimmedValue = e.target.value.trim();
                        setFieldValue('country', trimmedValue);
                        handleBlur(e);
                      }}
                      value={values.country}
                      label="Country"
                      variant="outlined"
                      size="large"
                      disabled={hotel === null}
                    />
                  </FormControl>
                </Grid>
                <Grid container justifyContent="space-around" alignItems="center" px={4} my={2}>
                  <FormControl sx={FormStyles} fullWidth>
                    <TextField
                      name="hotelType"
                      onChange={handleChange}
                      onBlur={(e) => {
                        const trimmedValue = e.target.value.trim();
                        setFieldValue('hotelType', trimmedValue);
                        handleBlur(e);
                      }}
                      value={values.hotelType}
                      label="Hotel Type"
                      variant="outlined"
                      size="large"
                      disabled={hotel === null}
                    />
                  </FormControl>
                </Grid>
                <Grid container justifyContent="space-around" alignItems="center" px={4} my={2}>
                  <FormControl sx={FormStyles} fullWidth>
                    <TextField
                      name="hotelCode"
                      onChange={handleChange}
                      onBlur={(e) => {
                        const trimmedValue = e.target.value.trim();
                        setFieldValue('hotelCode', trimmedValue);
                        handleBlur(e);
                      }}
                      value={values.hotelCode}
                      label="Hotel Code"
                      variant="outlined"
                      size="large"
                      disabled={hotel === null}
                    />
                  </FormControl>
                </Grid>
                <Grid container justifyContent="space-around" alignItems="center" px={4} my={2}>
                  <FormControl sx={FormStyles} fullWidth>
                    <TextField
                      name="ibe"
                      onChange={handleChange}
                      onBlur={(e) => {
                        const trimmedValue = e.target.value.trim();
                        setFieldValue('ibe', trimmedValue);
                        handleBlur(e);
                      }}
                      value={values.ibe}
                      label="IBE"
                      variant="outlined"
                      size="large"
                      disabled={hotel === null}
                    />
                  </FormControl>
                </Grid>
                <Grid container justifyContent="space-around" alignItems="center" px={4} my={2}>
                  <FormControl sx={FormStyles} fullWidth>
                    <TextField
                      name="interface"
                      onChange={handleChange}
                      onBlur={(e) => {
                        const trimmedValue = e.target.value.trim();
                        setFieldValue('interface', trimmedValue);
                        handleBlur(e);
                      }}
                      value={values.interface}
                      label="Interface"
                      variant="outlined"
                      size="large"
                      disabled={hotel === null}
                    />
                  </FormControl>
                </Grid>
                <Grid container justifyContent="space-around" alignItems="center" px={4} my={2}>
                  <FormControl sx={FormStyles} fullWidth>
                    <TextField
                      name="phram"
                      onChange={handleChange}
                      onBlur={(e) => {
                        const trimmedValue = e.target.value.trim();
                        setFieldValue('phram', trimmedValue);
                        handleBlur(e);
                      }}
                      value={values.phram}
                      label="PHRAM"
                      variant="outlined"
                      size="large"
                      disabled={hotel === null}
                    />
                  </FormControl>
                </Grid>
                <Grid container justifyContent="space-around" alignItems="center" px={4} my={2}>
                  <FormControl sx={FormStyles} fullWidth>
                    <TextField
                      name="phrdnName"
                      onChange={handleChange}
                      onBlur={(e) => {
                        const trimmedValue = e.target.value.trim();
                        setFieldValue('phrdnName', trimmedValue);
                        handleBlur(e);
                      }}
                      value={values.phrdnName}
                      label="PHRDN Name"
                      variant="outlined"
                      size="large"
                      disabled={hotel === null}
                    />
                  </FormControl>
                </Grid>
                <Grid container justifyContent="space-around" alignItems="center" px={4} my={2}>
                  <FormControl sx={FormStyles} fullWidth>
                    <TextField
                      name="pms"
                      onChange={handleChange}
                      onBlur={(e) => {
                        const trimmedValue = e.target.value.trim();
                        setFieldValue('pms', trimmedValue);
                        handleBlur(e);
                      }}
                      value={values.pms}
                      label="PMS"
                      variant="outlined"
                      size="large"
                      disabled={hotel === null}
                    />
                  </FormControl>
                </Grid>
                <Grid container justifyContent="space-around" alignItems="center" px={4} my={2}>
                  <FormControl sx={FormStyles} fullWidth>
                    <TextField
                      name="postalCode"
                      onChange={handleChange}
                      onBlur={(e) => {
                        const trimmedValue = e.target.value.trim();
                        setFieldValue('postalCode', trimmedValue);
                        handleBlur(e);
                      }}
                      value={values.postalCode}
                      label="Postal Code"
                      variant="outlined"
                      size="large"
                      disabled={hotel === null}
                    />
                  </FormControl>
                </Grid>
                <Grid container justifyContent="space-around" alignItems="center" px={4} my={2}>
                  <FormControl sx={FormStyles} fullWidth>
                    <TextField
                      name="propertyLatitude"
                      onChange={handleChange}
                      onBlur={(e) => {
                        const trimmedValue = e.target.value.trim();
                        setFieldValue('propertyLatitude', trimmedValue);
                        handleBlur(e);
                      }}
                      value={values.propertyLatitude}
                      label="Property Latitude"
                      variant="outlined"
                      size="large"
                      disabled={hotel === null}
                    />
                  </FormControl>
                </Grid>
                <Grid container justifyContent="space-around" alignItems="center" px={4} my={2}>
                  <FormControl sx={FormStyles} fullWidth>
                    <TextField
                      name="propertyLongitude"
                      onChange={handleChange}
                      onBlur={(e) => {
                        const trimmedValue = e.target.value.trim();
                        setFieldValue('propertyLongitude', trimmedValue);
                        handleBlur(e);
                      }}
                      value={values.propertyLongitude}
                      label="Property Longitude"
                      variant="outlined"
                      size="large"
                      disabled={hotel === null}
                    />
                  </FormControl>
                </Grid>
                <Grid container justifyContent="space-around" alignItems="center" px={4} my={2}>
                  <FormControl sx={FormStyles} fullWidth>
                    <TextField
                      name="streetAddress"
                      onChange={handleChange}
                      onBlur={(e) => {
                        const trimmedValue = e.target.value.trim();
                        setFieldValue('streetAddress', trimmedValue);
                        handleBlur(e);
                      }}
                      value={values.streetAddress}
                      label="Street Address"
                      variant="outlined"
                      size="large"
                      disabled={hotel === null}
                    />
                  </FormControl>
                </Grid>
              </OutlinedDiv>
            </Grid>

            <input ref={formElement} style={{ visibility: 'hidden' }} type="submit" />
            <Grid container direction="row" justifyContent="flex-end">
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  onClose();
                }}
                sx={{ marginLeft: 2, marginRight: 4 }}
              >
                CANCEL
              </Button>
              <Button
                type="submit"
                variant="contained"
                color="success"
                disabled={isSubmitting || hotel === null || !dirty || !isValid}
              >
                SUBMIT
              </Button>
            </Grid>
          </form>
        )}
      </Formik>
    </Grid>
  );
};
//
export default HotelMetaDataView;
