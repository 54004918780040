/* eslint-disable react/no-this-in-sfc */
import { useState, useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  subDays,
  endOfDay,
  isSameDay,
  differenceInCalendarDays,
  startOfDay,
  isBefore,
} from 'date-fns';
import { BOOKING_DATE_START_DATE } from 'modules/common/constants/date-range';
import { InputAdornment } from '@mui/material';
import { ClickAwayListener } from '@mui/base';
import { DefinedRange, DateRange } from 'react-date-range';
import styles from 'modules/dashboard/components/tab-container/inner-filter/inner-filter.module.scss';
import { TextField } from './style';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { selectStayDatesPresetList } from '../../../users/selectors';

/**
 * Custom booking date range picker common component that uses across modules for select booking date range
 * @param {Function} handleChange - function for setting data range
 * @param {Array} ranges - start date and end data of the picker
 * @param {String} label - textfield label
 * @param {String} adornment - whether we need to adornment is beginning or not
 * @param {String} dateValue - selected date value
 * @param {String} cssClassName - use style for entire component
 * @param {String} maxDate - maximum date what we need to define
 * @param {String} targetDate - stay date of the component
 * @param {String} dataFeedDate - latest booking date of the hotel
 * @returns
 */
const CustomBookingDateRangePicker = (props) => {
  const {
    handleChange,
    ranges,
    label,
    adornment,
    dateValue,
    cssClassName,
    maxDate,
    targetDate,
    dataFeedDate,
    id = '',
  } = props;
  //
  const presetList = useSelector(selectStayDatesPresetList);
  const [open, setOpen] = useState(false);
  const [formattedPresetList, setFormattedPresetList] = useState([]);
  //
  const refTarget = useRef(null);
  //
  useEffect(() => {
    if (presetList?.length > 0) {
      const formattedSet = presetList
        ?.filter(
          (set) =>
            isBefore(startOfDay(new Date(set?.startDate)), startOfDay(new Date())) &&
            isBefore(startOfDay(new Date(set?.endDate)), startOfDay(new Date()))
        )
        ?.map((set) => ({
          label: set?.presetName,
          range: () => ({
            startDate: startOfDay(new Date(set?.startDate)),
            endDate: endOfDay(new Date(set?.endDate)),
          }),
          isSelected(range) {
            const definedRange = this.range();
            return (
              isSameDay(range.startDate, definedRange.startDate) &&
              isSameDay(range.endDate, definedRange.endDate)
            );
          },
        }));
      setFormattedPresetList(formattedSet);
    }
  }, [presetList]);
  //
  let dateRange;
  if (ranges) {
    dateRange = [
      {
        startDate: new Date(ranges[0].startDate),
        endDate: new Date(ranges[0].endDate),
        key: 'selection',
      },
    ];
  } else {
    dateRange = [
      {
        startDate: '',
        endDate: '',
        key: 'selection',
      },
    ];
  }
  //
  return (
    <ClickAwayListener onClickAway={() => setOpen(false)}>
      <div className="calendarWrap">
        <TextField
          id="booking date range picker"
          InputProps={{
            startAdornment: <InputAdornment position="start">{adornment}</InputAdornment>,
          }}
          label={label}
          value={dateValue}
          size="small"
          color="primary"
          onClick={() => setOpen(!open)}
          sx={{
            '& .MuiOutlinedInput-input': {
              fontSize: 12,
            },
            '& .MuiOutlinedInput-root': {
              height: 'fit-content',
              padding: 0,
            },
          }}
          className="booking-font"
        />
        <div ref={refTarget}>
          {open && (
            <>
              <DefinedRange
                onChange={handleChange}
                ranges={dateRange}
                className={styles.bookingDefinedDateEement}
                inputRanges={[
                  {
                    label: 'Trailing days',
                    range: (value) => ({
                      startDate: subDays(new Date(targetDate.endDate), value),
                      endDate: subDays(new Date(targetDate.endDate), 1),
                    }),
                    getCurrentValue(range) {
                      if (!range.startDate) return '∞';
                      return differenceInCalendarDays(
                        new Date(targetDate.endDate),
                        range.startDate
                      );
                    },
                  },
                  {
                    label: 'Last Days',
                    range: (value) => ({
                      startDate: subDays(new Date(dataFeedDate), value),
                      endDate: subDays(new Date(dataFeedDate), 1),
                    }),
                    getCurrentValue(range) {
                      if (!range.startDate) return '∞';
                      return differenceInCalendarDays(new Date(dataFeedDate), range.startDate);
                    },
                  },
                ]}
                staticRanges={[
                  {
                    label: 'Last Day',
                    range: () => ({
                      startDate: endOfDay(new Date(dataFeedDate)),
                      endDate: endOfDay(new Date(dataFeedDate)),
                    }),
                    isSelected(range) {
                      const definedRange = this.range();
                      return (
                        isSameDay(range.startDate, definedRange.startDate) &&
                        isSameDay(range.endDate, definedRange.endDate)
                      );
                    },
                  },
                  {
                    label: 'Last 7 Days',
                    range: () => ({
                      startDate: subDays(endOfDay(new Date(dataFeedDate)), 7),
                      endDate: subDays(endOfDay(new Date(dataFeedDate)), 1),
                    }),
                    isSelected(range) {
                      const definedRange = this.range();
                      return (
                        isSameDay(range.startDate, definedRange.startDate) &&
                        isSameDay(range.endDate, definedRange.endDate)
                      );
                    },
                  },
                  {
                    label: 'Last 14 Days',
                    range: () => ({
                      startDate: subDays(endOfDay(new Date(dataFeedDate)), 14),
                      endDate: subDays(endOfDay(new Date(dataFeedDate)), 1),
                    }),
                    isSelected(range) {
                      const definedRange = this.range();
                      return (
                        isSameDay(range.startDate, definedRange.startDate) &&
                        isSameDay(range.endDate, definedRange.endDate)
                      );
                    },
                  },
                  {
                    label: 'Trailing 7 days',
                    range: () => ({
                      startDate: subDays(endOfDay(new Date(targetDate.endDate)), 7),
                      endDate: subDays(endOfDay(new Date(targetDate.endDate)), 1),
                    }),
                    isSelected(range) {
                      const definedRange = this.range();
                      return (
                        isSameDay(range.startDate, definedRange.startDate) &&
                        isSameDay(range.endDate, definedRange.endDate)
                      );
                    },
                  },
                  {
                    label: 'Trailing 14 days',
                    range: () => ({
                      startDate: subDays(endOfDay(new Date(targetDate.endDate)), 14),
                      endDate: subDays(endOfDay(new Date(targetDate.endDate)), 1),
                    }),
                    isSelected(range) {
                      const definedRange = this.range();
                      return (
                        isSameDay(range.startDate, definedRange.startDate) &&
                        isSameDay(range.endDate, definedRange.endDate)
                      );
                    },
                  },
                  ...formattedPresetList,
                ]}
              />
              <DateRange
                id={id}
                onChange={handleChange}
                editableDateInputs
                moveRangeOnFirstSelection={false}
                ranges={dateRange}
                months={1}
                direction="horizontal"
                className={cssClassName}
                maxDate={maxDate}
                minDate={new Date(BOOKING_DATE_START_DATE)}
                dateDisplayFormat="yyyy-MM-dd"
              />
            </>
          )}
        </div>
      </div>
    </ClickAwayListener>
  );
};
//
export default CustomBookingDateRangePicker;
