import { useEffect, useRef, useState } from 'react';
import { ClickAwayListener } from '@mui/material';
import { useDispatch } from 'react-redux';
import { summaryActions } from 'modules/dashboard/components/tab-container/summary-tab/slice';
import { trendActions } from 'modules/dashboard/components/tab-container/trend-tabs/slice';
import { DateRange } from 'react-date-range';
import { isValid, parse } from 'date-fns';
import { UiController, events } from 'modules/common/ui-controller';
import { formatDateLabel, getComparisonDateRange } from 'modules/dashboard/functions';
import { REPORT_TYPE } from 'modules/dashboard/constants';
import { TextField } from 'modules/dashboard/components/tab-container/inner-filter/style';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import styles from 'modules/dashboard/components/tab-container/inner-filter/inner-filter.module.scss';
import { webClickActions } from 'modules/dashboard/components/tab-container/web-click-tab/slice';
import { iPreferActions } from 'modules/dashboard/components/tab-container/i-prefer-tab/slice';
import { workbookActions } from '../../../workbook-report/slice';

/**
 * Custom date picker for each widget with fixed duration as stay date
 * @param {String} reportType - Report type of  active tab
 * @param {Date} targetDate - Stay date of active tab
 * @param {String} customDate - Custom date of active tab ("start"/ "end")
 * @param {Date} startDate - Comparison start date of active tab
 * @param {Date} endDate - Comparison end date of active tab
 * @returns
 */
const CustomDatePicker = ({ reportType, targetDate, customDate, startDate, endDate }) => {
  const dispatch = useDispatch();
  //
  const [open, setOpen] = useState(false);
  const [customDateRangeCommand, setCustomDateRangeCommand] = useState(null);
  //
  const refTarget = useRef(null);
  // Update global state of comparison date to trigger Power BI filter
  const handleChange = (item) => {
    // Set selected date based on respective dashboard actions based on report type
    // Eg: setSummaryDateSelected is used to set selected date of summary dashboard
    switch (reportType) {
      case REPORT_TYPE.SUMMARY:
        dispatch(summaryActions.setSummaryDateSelected(item.selection.startDate.toISOString()));
        break;
      case REPORT_TYPE.TREND_TIME:
        dispatch(trendActions.setTrendTimeDateSelected(item.selection.startDate.toISOString()));
        break;
      case REPORT_TYPE.WEB_CLICK:
        dispatch(webClickActions.setWebClickDateSelected(item.selection.startDate.toISOString()));
        break;
      case REPORT_TYPE.I_PREFER:
        dispatch(iPreferActions.setIPreferDateSelected(item.selection.startDate.toISOString()));
        break;
      case REPORT_TYPE.WORKBOOK_REPORT:
        dispatch(workbookActions.setWorkbookDateSelected(item.selection.startDate.toISOString()));
        break;
      default:
        break;
    }
    // Calculate calculate comparison dates based on selected stay date gap
    if (targetDate) {
      const dateRange = getComparisonDateRange(
        item?.selection?.startDate,
        targetDate.startDate,
        targetDate.endDate,
        customDate
      );
      //
      switch (reportType) {
        case REPORT_TYPE.SUMMARY:
          dispatch(
            summaryActions.setSummaryStartDate(dateRange?.comparisonStartDate.toISOString())
          );
          dispatch(summaryActions.setSummaryEndDate(dateRange?.comparisonEndDate.toISOString()));
          break;
        case REPORT_TYPE.TREND_TIME:
          dispatch(
            trendActions.setTrendTimeStartDate(dateRange?.comparisonStartDate.toISOString())
          );
          dispatch(trendActions.setTrendTimeEndDate(dateRange?.comparisonEndDate.toISOString()));
          break;
        case REPORT_TYPE.WEB_CLICK:
          dispatch(
            webClickActions.setWebClickStartDate(dateRange?.comparisonStartDate.toISOString())
          );
          dispatch(webClickActions.setWebClickEndDate(dateRange?.comparisonEndDate.toISOString()));
          break;
        case REPORT_TYPE.I_PREFER:
          dispatch(
            iPreferActions.setIPreferStartDate(dateRange?.comparisonStartDate.toISOString())
          );
          dispatch(iPreferActions.setIPreferEndDate(dateRange?.comparisonEndDate.toISOString()));
          break;
        case REPORT_TYPE.WORKBOOK_REPORT:
          dispatch(
            workbookActions.setWorkbookStartDate(dateRange?.comparisonStartDate.toISOString())
          );
          dispatch(workbookActions.setWorkbookEndDate(dateRange?.comparisonEndDate.toISOString()));
          break;
        default:
          break;
      }
    }
    setOpen(false);
  };
  // Command flow implementation for comparison date range
  const setCustomDateRangeHandler = (value) => {
    setCustomDateRangeCommand(value);
  };
  //
  useEffect(() => {
    if (customDateRangeCommand !== null) {
      const datesExtracted = customDateRangeCommand?.split('-');
      //
      if (customDate === datesExtracted[0]) {
        const parsedDate1 = parse(datesExtracted[1].trim(), 'yyyyMMdd', new Date());
        let obj = {};
        if (isValid(parsedDate1)) {
          obj = {
            startDate: parsedDate1,
            endDate: parsedDate1,
          };
        }
        handleChange({ selection: { ...obj, key: 'selection' } });
        setCustomDateRangeCommand(null);
      }
    }
  }, [customDateRangeCommand, customDate]);
  // Subscribe events to UI controller
  useEffect(() => {
    UiController.subscribe(events.SET_CUSTOM_DATE_RANGE, setCustomDateRangeHandler);
    return () => {
      UiController.unsubscribe(events.SET_CUSTOM_DATE_RANGE, setCustomDateRangeHandler);
    };
  }, []);
  //
  return (
    <ClickAwayListener onClickAway={() => setOpen(false)}>
      <div className="calendarWrap">
        <TextField
          onClick={() => setOpen(!open)}
          fullWidth
          value={
            endDate !== null
              ? formatDateLabel({
                  startDate: new Date(startDate),
                  endDate: new Date(endDate),
                })
              : 'Select Custom Date'
          }
          size="small"
          color="primary"
          sx={{
            '& .MuiOutlinedInput-input': {
              fontSize: 12,
            },
            '& .MuiOutlinedInput-root': {
              height: 'fit-content',
              width: 'fit-content',
              padding: 0,
            },
          }}
          className="booking-font"
        />
        <div ref={refTarget}>
          {open && (
            <DateRange
              onChange={(item) => handleChange(item)}
              className={styles.bookingCalendarElement}
              showDateDisplay={false}
              ranges={[
                {
                  startDate: startDate ? new Date(startDate) : new Date(),
                  endDate: endDate ? new Date(endDate) : new Date(),
                  key: 'selection',
                },
              ]}
              inputRanges={[]}
              staticRanges={[]}
              dateDisplayFormat="yyyy-MM-dd"
            />
          )}
        </div>
      </div>
    </ClickAwayListener>
  );
};
//
export default CustomDatePicker;
