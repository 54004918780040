/* eslint-disable no-underscore-dangle */
import { useEffect, useState } from 'react';
import { Autocomplete, Box, Button, Grid } from '@mui/material';
import { Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, PresetStayDateRangePicker } from 'modules/common/components';
import styles from 'modules/dashboard/components/tab-container/inner-filter/inner-filter.module.scss';
import { selectNotification } from 'modules/common/notifications/selectors';
import { selectStayDatesPresetList } from 'modules/users/selectors';
import ERROR_TYPES from 'modules/common/constants/error-types';
import { presetDatesValidation } from 'modules/users/validation/add-user-form-validation';
import { notificationActions } from 'modules/common/notifications/slice';
import { usersActions } from 'modules/users/slice';
import { YEAR_MONTH_DAY_PATTERN } from 'modules/common/constants/date-range';
import format from 'date-fns/format';
import { Alert, TextField } from './style';
import OutlinedDiv from './outer-card';

/**
 * Dates preset implementation to add , delete date presets
 * @param {string} organizationId - organization id
 * @param {string} userId - user id
 * @param {function} onClose - function to cancel the action
 * @returns
 */
const StayDatePreset = ({ organizationId, userId, onClose }) => {
  const dispatch = useDispatch();
  const presetList = useSelector(selectStayDatesPresetList);
  const [dates, setDates] = useState({
    startDate: new Date().toISOString(),
    endDate: new Date().toISOString(),
    key: 'selection',
  });
  //
  const [formattedPresetList, setFormattedPresetList] = useState([]);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [selectDeleteId, setDeleteId] = useState(null);
  // set formatted user details
  useEffect(() => {
    if (presetList?.length > 0) {
      const formattedSet = presetList?.map((set) => ({
        id: set?._id,
        label: set?.presetName,
        startDate: set?.startDate,
        endDate: set?.endDate,
      }));

      setFormattedPresetList(formattedSet);
    }
  }, [presetList]);

  const notification = useSelector(selectNotification);

  const formatDateLabel = (item, pattern = YEAR_MONTH_DAY_PATTERN) =>
    `${format(new Date(item.startDate), pattern)} to ${format(new Date(item.endDate), pattern)}`;

  const handleChanges = (item) => {
    setDates((prev) => ({
      ...prev,
      startDate: item?.selection.startDate,
      endDate: item?.selection.endDate,
    }));
  };

  // update new password
  const onSubmitDatePreset = async (values) => {
    let valueObj = {
      organizationId,
      userId,
      presetName: values?.presetName,
      startDate: dates?.startDate,
      endDate: dates.endDate,
    };
    if (selectDeleteId) {
      valueObj = {
        ...valueObj,
        customDateRangeId: selectDeleteId,
      };
    }
    dispatch(usersActions.saveStayDatesPreset(valueObj));
  };
  // define initial values
  const initialValues = {
    presetName: '',
  };
  // reset notifications on first render
  useEffect(() => {
    dispatch(notificationActions.resetNotification());
  }, []);
  // delete selected date preset
  const deletePreset = (filterId) => {
    if (filterId !== null) {
      dispatch(
        usersActions.deleteStayDatesPreset({
          organizationId,
          userId,
          customDateRangeId: filterId,
        })
      );
      setDeleteId(null);
    }
  };
  //
  return (
    <Box
      xs={12}
      mt={2}
      justifyContent="center"
      alignItems="center"
      component={Grid}
      container
      item
      display="flex"
      direction="column"
      sx={{ backgroundColor: 'white', flexShrink: 0 }}
    >
      <Formik
        initialValues={initialValues}
        validationSchema={presetDatesValidation}
        onSubmit={onSubmitDatePreset}
      >
        {({
          errors,
          handleBlur,
          handleSubmit,
          handleChange,
          values,
          isSubmitting,
          touched,
          setFieldValue,
        }) => (
          <form noValidate autoComplete="off" onSubmit={handleSubmit}>
            <OutlinedDiv label="Date Range">
              {notification?.isEnabled && notification?.type === ERROR_TYPES.ERROR && (
                <Alert mt={2} mb={3} severity={notification?.type}>
                  {notification?.message}
                </Alert>
              )}

              <Grid
                container
                flexDirection="column"
                alignItems="flex-end"
                // px={4}
                my={4}
                // xs={12}
                // item
              >
                <Autocomplete
                  fullWidth
                  sx={{
                    mb: 4,
                    mt: 2,
                  }}
                  options={formattedPresetList ?? []}
                  getOptionLabel={(option) => (option.label ? option.label : '')}
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  filterSelectedOptions
                  onChange={(event, value) => {
                    setDeleteId(value?.id);
                    setFieldValue('presetName', value?.label);
                    setDates((prev) => ({
                      ...prev,
                      startDate: value?.startDate,
                      endDate: value?.endDate,
                    }));
                  }}
                  disableClearable
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      InputLabelProps={{ shrink: true }}
                      label="Select Preset"
                      size="small"
                      color="primary"
                    />
                  )}
                />

                <TextField
                  size="small"
                  name="presetName"
                  fullWidth
                  value={values?.presetName}
                  error={Boolean(touched.presetName && errors.presetName)}
                  helperText={touched.presetName && errors.presetName}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  my={2}
                  label="Preset Name"
                  InputLabelProps={{ shrink: true }}
                  onKeyDown={(e) => {
                    e.stopPropagation();
                  }}
                />

                <PresetStayDateRangePicker
                  id="auto-stay-date-picker"
                  handleChange={handleChanges}
                  ranges={[dates]}
                  dateValue={formatDateLabel(dates)}
                  cssClassName={styles.calendarElement2}
                />
              </Grid>
            </OutlinedDiv>
            <Grid container direction="row" justifyContent="flex-end">
              <Button
                variant="contained"
                color="primary"
                onClick={(e) => {
                  e.preventDefault();
                  setOpenDeleteModal(true);
                }}
                disabled={selectDeleteId === null}
                sx={{ marginLeft: 2, marginRight: 4 }}
              >
                DELETE
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  setDeleteId(null);
                  onClose();
                }}
                sx={{ marginLeft: 2, marginRight: 4 }}
              >
                CANCEL
              </Button>
              <Button
                type="submit"
                variant="contained"
                color="success"
                disabled={isSubmitting} // || (presetList?.length >= 3 && selectDeleteId === null)}
              >
                SAVE
              </Button>
            </Grid>
            <Modal
              open={openDeleteModal}
              handleClose={() => setOpenDeleteModal(false)}
              title="Delete Preset"
              content="Are you sure you want to delete the dates preset?"
              handleSuccess={() => {
                deletePreset(selectDeleteId);
                onClose();
              }}
              closeLabel="Cancel"
              successLabel="Delete"
              variant="contained"
              color="error"
            />
          </form>
        )}
      </Formik>
    </Box>
  );
};
//
export default StayDatePreset;
