/* eslint-disable dot-notation ,radix */
import { useState } from 'react';
import { models } from 'powerbi-client';
import { isEmpty } from 'modules/common/helpers/object';
import { formatDate } from 'modules/dashboard/functions';
import { TIME_PATTERN, YEAR_MONTH_DAY_PATTERN } from 'modules/common/constants/date-range';
import { Grid, IconButton, Typography } from '@mui/material';
import { Loader } from 'modules/common/components';
import DownloadIcon from '@mui/icons-material/Download';
import NoDataDialog from 'modules/dashboard/components/tab-container/export-visual-data/components/no-data';
import { useSelector } from 'react-redux';
import { selectAuthUser } from 'modules/common/auth/selectors';
import * as XLSX from 'xlsx-js-style';
import { selectHotelName } from 'modules/dashboard/selectors';
import { styleXlsxFiles } from './style-xlsx';

/**
 * Export Data component to download visual data in format
 * @param {any} report - PowerBI report
 * @param {boolean} isLoaded - if the visual is loaded or not
 * @param {string} fileName - Name for the xlsx export file
 * @param {string} rawCsv - csv from other components
 * @returns
 */
const ExportToExcel = ({ report, isLoaded, fileName }) => {
  //
  const currentUser = useSelector(selectAuthUser);
  const selectedHotel = useSelector(selectHotelName);
  //
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [isDialog, setIsDialog] = useState(false);
  //
  const resetInitData = () => {
    setLoading(false);
    setIsDialog(true);
  };
  //

  const extractAllMetaFromPBI = async (visualComponents) => {
    let detailsList = [];
    detailsList = await Promise.all(
      visualComponents.map(async (component) => {
        let data = await component.exportData(models.ExportDataType.Summarized);
        data = data.data.split('\r\n').filter((cmpnt) => cmpnt.includes(':'));
        return data[0];
      })
    );

    return detailsList;
  };

  const exportPbiData = async () => {
    setLoading(true);
    if (isLoaded && !isEmpty(report)) {
      //
      try {
        const pages = await report.getPages();
        const currentPage = pages.filter((page) => page.isActive)[0];
        const visuals = await currentPage.getVisuals();
        //
        const visualDetailsComponents = visuals.filter((visual) => visual.type === 'multiRowCard');
        let visualMeta = await extractAllMetaFromPBI(visualDetailsComponents).then(
          (metaArray) => metaArray
        );
        //
        const visual = visuals.filter((v) => v.type === 'tableEx')[0];
        const result = await visual.exportData(models.ExportDataType.Summarized);

        result.data = result?.data.replaceAll(', ', '####');
        // Process meta data
        visualMeta = visualMeta.join(',').replaceAll(',', '\r\n').replaceAll(':', ',');
        //
        let workbookData = `Username, ${currentUser?.username}\r\n`;
        workbookData += `Report Generation Date, ${formatDate(
          new Date(),
          YEAR_MONTH_DAY_PATTERN
        )} ${formatDate(new Date(), TIME_PATTERN)}\r\n`;
        workbookData += `Hotel Name, ${selectedHotel?.label}\r\n`;
        workbookData += `${visualMeta} \r\n\r\n`;
        const aoa = result?.data.split('\r\n').map((row) => row.split(','));
        const worksheet = XLSX.utils.aoa_to_sheet(aoa);

        // update the sheet with csv header
        const updatedSheet = XLSX.read(workbookData + XLSX.utils.sheet_to_csv(worksheet), {
          type: 'string',
        }).Sheets['Sheet1'];
        //
        // format and style the csv export
        styleXlsxFiles(updatedSheet, fileName);

        setLoading(false);
      } catch (error) {
        setMessage('Something went wrong', resetInitData());
      }
    } else {
      setMessage('Visual is still loading', resetInitData());
    }
  };
  //
  return (
    <div>
      <Loader loading={loading}>
        <Grid container direction="row" alignItems="center">
          <Typography
            sx={{
              mr: 1,
            }}
          >
            Export Data
          </Typography>
          <IconButton
            size="small"
            onClick={() => exportPbiData()}
            sx={{
              '& .MuiSvgIcon-root': {
                width: '1.25rem',
                height: '1.25rem',
              },
            }}
          >
            <DownloadIcon />
          </IconButton>
        </Grid>
        <NoDataDialog open={isDialog} onClose={() => setIsDialog(false)} message={message} />
      </Loader>
    </div>
  );
};
//
export default ExportToExcel;
