import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectVisualFilters,
  selectHotelName,
  selectTargetDate,
  selectPmsSync,
  selectEnableHotelGroups,
  selectReportType,
  selectLocalCurrency,
} from 'modules/dashboard/selectors';
import {
  generateRegionalMapShareUrl,
  getBookingDateSchema,
  getHotelIdSchema,
  getPmsSyncToggleSchema,
  getTargetDateSchema,
  getChartBreakdownFilterList,
  getSegmentFocusOnSchema,
  getDashboardPmsSyncSchema,
} from 'modules/dashboard/components/tab-container/inner-filter/functions';
import {
  selectRegionMapBookingDate,
  selectRegionMapFocusOn,
  selectRegionMapChartBreakdown,
  selectIsRegionDetailed,
  selectRegionalMapOtb,
  selectRegionalBreakdownTabularView,
  selectRegionMapFilterList,
  selectIsFocusOnSet,
} from 'modules/dashboard/components/tab-container/regional-map-tab/selectors';
import { regionalMapActions } from 'modules/dashboard/components/tab-container/regional-map-tab/slice';
import { getSelectedBreakdown } from 'modules/dashboard/functions';
import writeLog from 'modules/common/utils/filter-log';
import { REPORT_TYPE } from 'modules/dashboard/constants';
import { storeVisualMapper } from 'modules/common/utils/filter-visual-mapper';
import VISUAL_MAPPER from 'modules/common/utils/visual-mapper';
/**
 * Custom hook to generate region map filter schemas
 */
const useRegionMap = () => {
  const dispatch = useDispatch();
  //
  const bookingDate = useSelector(selectRegionMapBookingDate);
  const targetDate = useSelector(selectTargetDate);
  const hotelId = useSelector(selectHotelName);
  const visualFilters = useSelector(selectVisualFilters);
  const pmsSyncToggle = useSelector(selectPmsSync);
  const focusOn = useSelector(selectRegionMapFocusOn);
  const chartBreakdown = useSelector(selectRegionMapChartBreakdown);
  const isDetailed = useSelector(selectIsRegionDetailed);
  const customBooking = useSelector(selectRegionalMapOtb);
  const regionalBreakdownTabularView = useSelector(selectRegionalBreakdownTabularView);
  const enableHotelGroups = useSelector(selectEnableHotelGroups);
  const reportType = useSelector(selectReportType);
  const filterList = useSelector(selectRegionMapFilterList);
  const localCurrency = useSelector(selectLocalCurrency);
  const IsFocusOnSet = useSelector(selectIsFocusOnSet);

  // Generate filter list for regional map visual
  useEffect(() => {
    if (
      !IsFocusOnSet ||
      reportType !== REPORT_TYPE.REGION_MAP ||
      (!chartBreakdown && !targetDate && !hotelId && !bookingDate)
    ) {
      return;
    }
    const bookingDateFilter = getBookingDateSchema(
      visualFilters,
      bookingDate?.startDate,
      bookingDate?.endDate
    );
    const targetDateFilter = getTargetDateSchema(
      visualFilters,
      targetDate?.startDate,
      targetDate?.endDate
    );
    // const hotelIdFilter = getHotelIdSchema(hotelId?.databricksId, visualFilters);
    let hotelIdFilter = [];
    if (enableHotelGroups) {
      const hotelListIds = hotelId?.hotels?.map((hotel) => hotel?.databricksId);
      hotelIdFilter = getHotelIdSchema(hotelListIds, visualFilters);
    } else {
      hotelIdFilter = getHotelIdSchema(hotelId?.databricksId, visualFilters);
    }
    const pmsSyncFilter = getPmsSyncToggleSchema(visualFilters, pmsSyncToggle);
    const chartBreakdownFilter = getChartBreakdownFilterList(
      visualFilters,
      chartBreakdown,
      isDetailed
    );
    const selectedBreakdown = getSelectedBreakdown(visualFilters, chartBreakdown, isDetailed);
    const segmentFocusOnFilter = getSegmentFocusOnSchema(
      selectedBreakdown,
      focusOn?.map((item) => item?.label)
    );
    const pmsSyncSchema = getDashboardPmsSyncSchema(pmsSyncToggle);
    //
    if (
      bookingDateFilter &&
      targetDateFilter &&
      hotelIdFilter &&
      chartBreakdownFilter &&
      segmentFocusOnFilter
    ) {
      const filters = [
        ...pmsSyncFilter,
        bookingDateFilter,
        targetDateFilter,
        hotelIdFilter,
        chartBreakdownFilter,
        ...segmentFocusOnFilter,
        ...pmsSyncSchema,
      ];

      if (JSON.stringify(filters) !== JSON.stringify(filterList)) {
        storeVisualMapper(VISUAL_MAPPER.reginalMapFilter);
        //
        writeLog('Regional Map Filter', filters);
        dispatch(regionalMapActions.setRegionMapFilterList(filters));
      }
    }
  }, [
    focusOn,
    chartBreakdown,
    bookingDate,
    visualFilters,
    targetDate,
    hotelId,
    pmsSyncToggle,
    isDetailed,
    enableHotelGroups,
    reportType,
    IsFocusOnSet,
  ]);
  // Triggers when filter values dependencies changes
  useEffect(() => {
    if (!focusOn && !chartBreakdown && !bookingDate && !targetDate && !hotelId) return;
    // Generate regional map filter url
    const regionalMapUrl = generateRegionalMapShareUrl(
      pmsSyncToggle,
      hotelId,
      targetDate,
      customBooking,
      visualFilters,
      chartBreakdown,
      isDetailed,
      bookingDate,
      focusOn,
      regionalBreakdownTabularView,
      hotelId,
      enableHotelGroups,
      localCurrency
    );
    //
    dispatch(regionalMapActions.setRegionalMapUrl(regionalMapUrl));
  }, [
    pmsSyncToggle,
    chartBreakdown,
    bookingDate,
    visualFilters,
    customBooking,
    targetDate,
    hotelId,
    isDetailed,
    focusOn,
    regionalBreakdownTabularView,
    hotelId,
    enableHotelGroups,
    localCurrency,
  ]);
};
//
export default useRegionMap;
