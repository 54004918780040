import { Stack, Grid, Typography } from '@mui/material';
import { DateTime } from 'luxon';
import { useSelector } from 'react-redux';
import { selectDataLastUpdated } from 'modules/dashboard/selectors';
import { DATE_TIME_PATTERN } from '../../../../common/constants/date-range';

/**
 * Data updated labels for each widget
 * @param {Date} latestDate - Latest booking date of selected hotel
 * @param {String} hotelTimezone - Timezone of selected hotel
 * @returns
 */
const DataUpdatedLabel = ({ latestDate, hotelTimezone }) => {
  const lastUpdatedDate = useSelector(selectDataLastUpdated);
  // Convert last updated date and latest booking date to hotel specific timezone
  const lastUpdatedDateUtc = DateTime.fromSQL(lastUpdatedDate, { zone: 'utc' });
  const hotelTimezoneLongName = lastUpdatedDateUtc.setZone(hotelTimezone).offsetNameLong;
  const formattedLastUpdatedDate = lastUpdatedDateUtc
    .setZone(hotelTimezone)
    .toFormat(DATE_TIME_PATTERN);
  const lastBookingDateUtc = latestDate ? DateTime.fromSQL(latestDate, { zone: 'utc' }) : '';
  const formattedLastBookingDate = latestDate
    ? lastBookingDateUtc.setZone(hotelTimezone).toFormat(DATE_TIME_PATTERN)
    : '';
  //
  return (
    <Stack direction="column" spacing={1} xs={12} paddingTop={1} alignSelf="center" marginLeft={2}>
      <Grid container direction="row">
        <Grid item>
          <Typography
            sx={{ fontSize: 11, color: '#9e9e9e', whiteSpace: 'pre-wrap' }}
            variant="inherit"
          >
            Data updated:{'      '}
          </Typography>
        </Grid>
        <Grid item>
          <Typography sx={{ fontSize: 11 }}>
            {lastUpdatedDate ? formattedLastUpdatedDate : ''}
          </Typography>
        </Grid>
      </Grid>
      <Grid container direction="row">
        <Grid item>
          <Typography
            sx={{ fontSize: 11, color: '#9e9e9e', whiteSpace: 'pre-wrap' }}
            variant="inherit"
          >
            Last transaction:{'  '}
          </Typography>
        </Grid>
        <Grid item>
          <Typography sx={{ fontSize: 11 }}>
            {latestDate ? formattedLastBookingDate : ''}
          </Typography>
        </Grid>
      </Grid>
      <Grid container direction="row">
        <Grid item>
          <Typography
            sx={{ fontSize: 11, color: '#9e9e9e', whiteSpace: 'pre-wrap' }}
            variant="inherit"
          >
            Timezone:{'              '}
          </Typography>
        </Grid>
        <Grid item>
          <Typography sx={{ fontSize: 11 }}>{hotelTimezoneLongName ?? ''}</Typography>
        </Grid>
      </Grid>
    </Stack>
  );
};
//
export default DataUpdatedLabel;
