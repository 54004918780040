import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectVisualFilters,
  selectPmsSync,
  selectTargetDate,
  selectPaceHorizon,
  selectPaceVariable,
  selectHotelName,
  selectForecastFilter,
  selectEnableHotelGroups,
  selectReportType,
} from 'modules/dashboard/selectors';
import {
  endOfMonth,
  isFirstDayOfMonth,
  isLastDayOfMonth,
  isSameDay,
  isSaturday,
  isSunday,
  lastDayOfYear,
  nextSaturday,
  previousSunday,
  startOfMonth,
  startOfYear,
} from 'date-fns';
import { PACE_HORIZON_SELECTION, REPORT_TYPE, TAGS } from 'modules/dashboard/constants';
import {
  getAlignmentAndDateComparisonSchema,
  getBookingDateSchema,
  getDashboardPmsSyncSchema,
  getHotelIdSchema,
  getIsForecastSchema,
  getPaceGranularitySchema,
  getPaceVariableSchema,
  getPaceWidgetPaceHorizonSchema,
  getPmsSyncSchema,
  getRevenueForecastSchema,
  getRoomForecastSchema,
  getSegmentFocusOnSchema,
  // getSymmetricComparisonSchema,
  getTargetDateSchema,
  percentageSchema,
} from 'modules/dashboard/components/tab-container/inner-filter/functions';
import {
  selectIsPrimary,
  selectIsForecast,
  selectPaceAlignment,
  selectPaceAlignmentToggle,
  selectPaceDateComparison,
  // selectPaceSymmetricComparisonToggle,
  selectPaceTimeOccupiedRoomFilterList,
  selectPaceTimeRevenueFilterList,
  selectPercentage,
  selectPaceBookingPaceWindow,
  selectPaceBookingDate,
  selectTabularView,
  selectAdjustedStartDate,
  selectAdjustedEndDate,
  selectGranularity,
  selectPaceTimeTabularFilterList,
  selectPaceFocusOn,
  selectPaceChartBreakdown,
  selectIsPaceDetailed,
  selectIsFocusOnSet,
} from 'modules/dashboard/components/tab-container/pace-tabs/selectors';
import { paceActions } from 'modules/dashboard/components/tab-container/pace-tabs/slice';
import writeLog from 'modules/common/utils/filter-log';
import { storeVisualMapper } from 'modules/common/utils/filter-visual-mapper';
import VISUAL_MAPPER from 'modules/common/utils/visual-mapper';
import { getGranularity } from '../functions';
import { GRANULARITY } from '../constants';
import { getSelectedBreakdown } from '../../../../functions';

/**
 * Custom hook to generate summary widget filter schemas
 */
const usePaceTimeWidget = () => {
  const dispatch = useDispatch();
  //
  const targetDate = useSelector(selectTargetDate);
  const hotelId = useSelector(selectHotelName);
  const visualFilters = useSelector(selectVisualFilters);
  const pmsSyncToggle = useSelector(selectPmsSync);
  const paceHorizon = useSelector(selectPaceHorizon);
  const paceVariable = useSelector(selectPaceVariable);
  const forecastFilter = useSelector(selectForecastFilter);
  const bookingDate = useSelector(selectPaceBookingDate);
  const dateComparison = useSelector(selectPaceDateComparison);
  const percentage = useSelector(selectPercentage);
  // const symmetricComparisonToggle = useSelector(selectPaceSymmetricComparisonToggle);
  const alignment = useSelector(selectPaceAlignment);
  const alignmentToggle = useSelector(selectPaceAlignmentToggle);
  const enableHotelGroups = useSelector(selectEnableHotelGroups);
  const reportType = useSelector(selectReportType);
  const isSegment = useSelector(selectIsPrimary);
  const revenueFilterList = useSelector(selectPaceTimeRevenueFilterList);
  const occupiedRoomsFilterList = useSelector(selectPaceTimeOccupiedRoomFilterList);
  const tabularFilterList = useSelector(selectPaceTimeTabularFilterList);
  const isPaceForecast = useSelector(selectIsForecast);
  const bookingPaceWindow = useSelector(selectPaceBookingPaceWindow);
  const isTabular = useSelector(selectTabularView);
  const adjustedStartDate = useSelector(selectAdjustedStartDate);
  const adjustedEndDate = useSelector(selectAdjustedEndDate);
  const granularity = useSelector(selectGranularity);
  const focusOn = useSelector(selectPaceFocusOn);
  const chartBreakdown = useSelector(selectPaceChartBreakdown);
  const isDetailed = useSelector(selectIsPaceDetailed);
  const IsFocusOnSet = useSelector(selectIsFocusOnSet);
  //
  useEffect(() => {
    if (
      !IsFocusOnSet ||
      reportType !== REPORT_TYPE.PACE ||
      isSegment ||
      !dateComparison ||
      !alignment ||
      !paceHorizon ||
      !hotelId ||
      !visualFilters
    ) {
      return;
    }
    const targetDateFilter = getTargetDateSchema(
      visualFilters,
      targetDate?.startDate,
      targetDate?.endDate
    );
    //
    let startDate;
    let endDate;
    if (isTabular) {
      if (granularity === GRANULARITY.DAILY) {
        startDate = bookingDate?.startDate;
        endDate = bookingDate?.endDate;
      } else {
        startDate = adjustedStartDate;
        endDate = adjustedEndDate;
      }
    } else {
      startDate = bookingDate?.startDate;
      endDate = bookingDate?.endDate;
    }
    const bookingDateFilter = getBookingDateSchema(visualFilters, startDate, endDate);
    //
    const paceTimeRevenueVariableFilter = getPaceVariableSchema(
      visualFilters,
      'Revenue',
      TAGS.SUMMARY_PACE_VARIABLE
    );
    const paceTimeOccupiedRoomsVariableFilter = getPaceVariableSchema(
      visualFilters,
      'Occupied Rooms',
      TAGS.SUMMARY_PACE_VARIABLE
    );
    const roomForecastFilter = getRoomForecastSchema(visualFilters, pmsSyncToggle);
    const revenueForecastFilter = getRevenueForecastSchema(visualFilters, pmsSyncToggle);
    const percentageFilter = percentageSchema(visualFilters, percentage);
    let hotelIdFilter = [];
    if (enableHotelGroups) {
      const hotelListIds = hotelId?.hotels?.map((hotel) => hotel?.databricksId);
      hotelIdFilter = getHotelIdSchema(hotelListIds, visualFilters);
    } else {
      hotelIdFilter = getHotelIdSchema(hotelId?.databricksId, visualFilters);
    }
    const alignmentAndDateComparisonFilter = getAlignmentAndDateComparisonSchema(
      visualFilters,
      alignment?.id,
      dateComparison
    );
    // const symmetricComparisonFilter = getSymmetricComparisonSchema(
    //   visualFilters,
    //   symmetricComparisonToggle
    // );
    const paceHorizonFilter = getPaceWidgetPaceHorizonSchema(
      visualFilters,
      paceHorizon,
      bookingPaceWindow === PACE_HORIZON_SELECTION.BOOKING_PACE_HORIZON
    );
    const paceVariableFilter = getPaceVariableSchema(
      visualFilters,
      paceVariable,
      TAGS.PACE_VARIABLE
    );
    const pmsSyncFilter = getPmsSyncSchema(visualFilters, pmsSyncToggle, TAGS.PACE_WIDGET);
    const isForecastFilter = getIsForecastSchema(visualFilters, isPaceForecast);
    const granularityFilter = getPaceGranularitySchema(visualFilters, granularity);
    const selectedBreakdown = getSelectedBreakdown(visualFilters, chartBreakdown, isDetailed);
    const segmentFocusOnFilter = getSegmentFocusOnSchema(
      selectedBreakdown,
      focusOn?.map((item) => item?.label)
    );
    const pmsSyncSchema = getDashboardPmsSyncSchema(pmsSyncToggle);
    //
    if (
      paceTimeRevenueVariableFilter &&
      roomForecastFilter &&
      revenueForecastFilter &&
      paceTimeOccupiedRoomsVariableFilter &&
      percentageFilter &&
      targetDateFilter &&
      hotelIdFilter &&
      paceHorizonFilter &&
      paceVariableFilter &&
      alignmentAndDateComparisonFilter &&
      // symmetricComparisonFilter &&
      pmsSyncFilter &&
      isForecastFilter &&
      bookingDateFilter &&
      granularityFilter &&
      segmentFocusOnFilter
    ) {
      const filters = [
        roomForecastFilter,
        revenueForecastFilter,
        percentageFilter,
        ...alignmentAndDateComparisonFilter,
        targetDateFilter,
        //  symmetricComparisonFilter,
        hotelIdFilter,
        paceHorizonFilter,
        paceVariableFilter,
        ...pmsSyncFilter,
        bookingDateFilter,
        ...segmentFocusOnFilter,
        isForecastFilter,
        ...pmsSyncSchema,
      ];
      //
      const paceTimeRevenueFilterList = [paceTimeRevenueVariableFilter, ...filters];
      const paceTimeOccupiedFilterList = [paceTimeOccupiedRoomsVariableFilter, ...filters];
      if (JSON.stringify(revenueFilterList) !== JSON.stringify(paceTimeRevenueFilterList)) {
        storeVisualMapper(VISUAL_MAPPER.paceTimeRevenueFilter);
        //
        writeLog('Pace Time Revenue Filters', paceTimeRevenueFilterList);
        dispatch(paceActions.setPaceTimeRevenueFilterList(paceTimeRevenueFilterList));
      }
      if (JSON.stringify(occupiedRoomsFilterList) !== JSON.stringify(paceTimeOccupiedFilterList)) {
        storeVisualMapper(VISUAL_MAPPER.paceOccupiedRoomFilter);
        //
        writeLog('Pace Time Occupied Rooms Filters', paceTimeOccupiedFilterList);
        dispatch(paceActions.setPaceTimeOccupiedFilterList(paceTimeOccupiedFilterList));
      }
      // tabular filter list

      const commonTabularFilterList = [
        roomForecastFilter,
        revenueForecastFilter,
        percentageFilter,
        ...alignmentAndDateComparisonFilter,
        targetDateFilter,
        hotelIdFilter,
        paceHorizonFilter,
        paceVariableFilter,
        ...pmsSyncFilter,
        bookingDateFilter,
        granularityFilter,
        ...segmentFocusOnFilter,
        isForecastFilter,
        ...pmsSyncSchema,
      ];
      if (JSON.stringify(tabularFilterList) !== JSON.stringify(commonTabularFilterList)) {
        storeVisualMapper(VISUAL_MAPPER.paceTimeTabularFilterList);
        //
        writeLog('Pace Time Tabular Filters', commonTabularFilterList);
      }
      dispatch(paceActions.setPaceTimeTabularFilterList(commonTabularFilterList));
    }
  }, [
    pmsSyncToggle,
    visualFilters,
    forecastFilter,
    targetDate,
    hotelId,
    paceHorizon,
    paceVariable,
    percentage,
    alignment,
    // symmetricComparisonToggle,
    dateComparison,
    alignmentToggle,
    enableHotelGroups,
    reportType,
    isSegment,
    isPaceForecast,
    bookingDate,
    bookingPaceWindow,
    isTabular,
    adjustedStartDate,
    adjustedEndDate,
    granularity,
    focusOn,
    chartBreakdown,
    isDetailed,
    IsFocusOnSet,
  ]);

  // Triggered when booking date value changes
  useEffect(() => {
    // Set booking dates based on granularity
    const paceGranularity = getGranularity(
      bookingDate,
      paceHorizon,
      bookingPaceWindow,
      visualFilters
    );
    dispatch(paceActions.setGranularity(paceGranularity));
    if (paceGranularity === GRANULARITY.WEEKLY) {
      if (isSunday(new Date(bookingDate.startDate))) {
        dispatch(paceActions.setAdjustedStartDate(new Date(bookingDate.startDate).toISOString()));
      } else {
        dispatch(
          paceActions.setAdjustedStartDate(
            previousSunday(new Date(bookingDate.startDate)).toISOString()
          )
        );
      }
      if (isSaturday(new Date(bookingDate.endDate))) {
        dispatch(paceActions.setAdjustedEndDate(new Date(bookingDate.endDate).toISOString()));
      } else {
        dispatch(
          paceActions.setAdjustedEndDate(nextSaturday(new Date(bookingDate.endDate)).toISOString())
        );
      }
    } else if (paceGranularity === GRANULARITY.MONTHLY) {
      if (isFirstDayOfMonth(new Date(bookingDate.startDate))) {
        dispatch(paceActions.setAdjustedStartDate(new Date(bookingDate.startDate).toISOString()));
      } else {
        dispatch(
          paceActions.setAdjustedStartDate(
            startOfMonth(new Date(bookingDate.startDate)).toISOString()
          )
        );
      }
      if (isLastDayOfMonth(new Date(bookingDate.endDate))) {
        dispatch(paceActions.setAdjustedEndDate(new Date(bookingDate.endDate).toISOString()));
      } else {
        dispatch(
          paceActions.setAdjustedEndDate(endOfMonth(new Date(bookingDate.endDate)).toISOString())
        );
      }
    } else if (paceGranularity === GRANULARITY.YEARLY) {
      if (
        isSameDay(startOfYear(new Date(bookingDate.startDate)), new Date(bookingDate.startDate))
      ) {
        dispatch(paceActions.setAdjustedStartDate(new Date(bookingDate.startDate).toISOString()));
      } else {
        dispatch(
          paceActions.setAdjustedStartDate(
            startOfYear(new Date(bookingDate.startDate)).toISOString()
          )
        );
      }
      if (isSameDay(lastDayOfYear(new Date(bookingDate.endDate)), new Date(bookingDate.endDate))) {
        dispatch(paceActions.setAdjustedEndDate(new Date(bookingDate.endDate).toISOString()));
      } else {
        dispatch(
          paceActions.setAdjustedEndDate(lastDayOfYear(new Date(bookingDate.endDate)).toISOString())
        );
      }
    }
  }, [bookingDate, paceHorizon, bookingPaceWindow, visualFilters]);
};
//
export default usePaceTimeWidget;
